@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/animations";

.mdc-evolution-chip-set {
  padding: 6px 0;
}

.mat-mdc-chip {
  &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background: $secondary-gradient;
    color: $ebony;
  }
}

.mat-mdc-chip-input {
  height: 32px !important;
  align-self: center;
}
