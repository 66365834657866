$sizes: 3, 4, 6, 8, 12, 24;

@each $size in $sizes {
  .gap-#{$size} {
    margin: -#{$size}px !important;

    & + .gap-#{$size} {
      margin-top: #{$size}px !important;
    }

    > * {
      padding: #{$size}px !important;
    }
  }
  .gap-h-#{$size} {
    margin-left: -#{$size}px !important;
    margin-right: -#{$size}px !important;

    > * {
      padding-left: #{$size}px !important;
      padding-right: #{$size}px !important;
    }
  }
}
