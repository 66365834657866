@import "../../vars/colors";
@import "../../vars/animations";

mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-label {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(18px, 0, 0);
      }
    }

    &:not(.mat-disabled) {
      &:hover {
        .mat-slide-toggle-label {
          .mat-slide-toggle-thumb-container {
            .mat-slide-toggle-thumb {
              border-color: $de-york;

              &:before {
                background-color: $de-york;
              }
            }
          }

          .mat-slide-toggle-bar {
            background-color: $de-york;
          }
        }
      }

      .mat-slide-toggle-label {
        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            background: $white;
            border-color: $mountain-meadow;

            &:before {
              background-color: $mountain-meadow;
            }
          }
        }

        .mat-slide-toggle-bar {
          background-color: $mountain-meadow;
        }
      }
    }
  }

  &:not(.mat-checked) {
    &:not(.mat-disabled) {
      &:hover {
        .mat-slide-toggle-label {
          .mat-slide-toggle-thumb-container {
            .mat-slide-toggle-thumb {
              border-color: $lynch;
              background: $white;

              &:before {
                background-color: $lynch;
              }
            }
          }

          .mat-slide-toggle-bar {
            background-color: $lynch;
          }
        }
      }

      .mat-slide-toggle-label {
        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            background: $white;
            border-color: $bright-gray;

            &:before {
              background-color: $lynch;
            }
          }
        }

        .mat-slide-toggle-bar {
          background-color: $bright-gray;
        }
      }
    }
  }

  &.mat-disabled {
    opacity: 0.4;
    pointer-events: none;

    &.mat-checked {
      .mat-slide-toggle-label {
        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            border-color: $mountain-meadow;
            background: $white;

            &:before {
              background-color: $mountain-meadow;
            }
          }
        }

        .mat-slide-toggle-bar {
          background-color: $mountain-meadow;
        }
      }
    }

    &:not(.mat-checked) {
      .mat-slide-toggle-label {
        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            border-color: $bright-gray;
            background: $white;

            &:before {
              background-color: $bright-gray;
            }
          }
        }

        .mat-slide-toggle-bar {
          background-color: $bright-gray;
        }
      }
    }
  }

  &.loading {
    &.mat-checked {
      .mat-slide-toggle-thumb {
        background: conic-gradient($white, $mountain-meadow) !important;
      }
    }

    &:not(.mat-checked) {
      .mat-slide-toggle-thumb {
        background: conic-gradient($white, $mine-shaft) !important;
      }
    }

    .mat-slide-toggle-thumb {
      animation: rotateAnimation 1s infinite linear;

      &:before {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }

  .mat-slide-toggle-label {
    .mat-slide-toggle-bar {
      width: 40px;
      height: 22px;
      border-radius: 40px;
      transition: $base-transition;
    }

    .mat-slide-toggle-thumb-container {
      width: 22px;
      height: 22px;
      top: 0;

      .mat-slide-toggle-thumb {
        width: 22px;
        height: 22px;
        border: 2px solid;
        box-shadow: none;
        transition: $base-transition;

        &:before {
          content: "";
          width: 14px;
          height: 14px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 100%;
          opacity: 0;
          visibility: hidden;
        }
      }

      .mat-ripple {
        display: none !important;
      }
    }
  }
}

@keyframes rotateAnimation {
  100% {
    transform: rotate(360deg);
  }
}


mat-slide-toggle.success-error-state:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-label .mat-slide-toggle-bar {
  background-color: $mountain-meadow;
}

mat-slide-toggle.success-error-state:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-label .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  border-color: $mountain-meadow;
}

mat-slide-toggle.success-error-state.mat-checked:not(.mat-disabled) .mat-slide-toggle-label .mat-slide-toggle-bar {
  background-color: $primary-red;
}

mat-slide-toggle.success-error-state.mat-checked:not(.mat-disabled) .mat-slide-toggle-label .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  border-color: $primary-red;
}
