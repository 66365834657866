@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

.cdk-overlay-dark-backdrop {
  backdrop-filter: blur(4px);
  background: rgba($ebony, 0.7);
}

.non-desktop-fullscreen {
  @media screen and (max-width: $media-desktop-xs - 1px) {
    backdrop-filter: blur(4px);
    background: rgba($ebony, 0.7);
    position: fixed;
    left: 0 !important;
    top: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
