@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

mat-checkbox {

  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__ripple {
        display: none !important;
      }

      .mdc-checkbox__background {
        background: transparent;
        border-color: $mystic !important;
        border-width: 1px !important;
        border-radius: 4px;

        .mdc-checkbox__checkmark {
          display: none !important;
        }
      }

      .mdc-checkbox__native-control {
        &:enabled:checked ~ .mdc-checkbox__background,
        &:enabled:indeterminate ~ .mdc-checkbox__background,
        &[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
          background-color: $yellow-orange !important;
          border-color: $yellow-orange !important;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.66589L4.78821 7.45311L10.9484 1.29289' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &.wide-padding {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        padding-left: 17px;
      }
    }
  }

  &.mat-checkbox-disabled {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        color: inherit;
      }
    }
  }

  &.table-filter-checkbox {
    height: 39.75px;
    border-radius: 8px;
    background-color: $tuna;

    @media screen and (max-width: $media-mobile-md - 1px) {
      width: 100%;
      text-align: center;
    }

    .mat-checkbox-layout {
      padding: 0 16px;
      flex-direction: row-reverse;
      height: 100%;

      .mat-checkbox-inner-container {
        margin-left: 12px;
      }

      .mat-checkbox-label {
        padding-left: 0;
      }
    }
  }

  &.label-secondary {
    .mat-checkbox-label {
      opacity: 0.5;
    }
  }

  .mat-checkbox-layout {
    align-items: center;
    position: relative;
    white-space: normal;

    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
      margin-right: 0;

      .mat-checkbox-frame {
        border-radius: 4px;
        border: 1px solid $mystic;
      }

      .mat-checkbox-ripple {
        display: none !important;
      }

      .mat-checkbox-background {
        border-radius: 4px;

        svg {
          display: none;
        }
      }
    }

    .mat-checkbox-label {
      display: inline-flex;
      align-items: center;
      padding-left: 11px;
      line-height: 1.4;

      app-spinner {
        background: $white;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  &:not(.mat-checkbox-disabled):hover {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        .mat-checkbox-frame {
          border-color: $yellow-orange;
        }
      }
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          background-color: $yellow-orange;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.66589L4.78821 7.45311L10.9484 1.29289' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    &:not(.mat-checkbox-disabled):hover {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-background {
            background-color: $yellow-orange;
          }
        }
      }
    }
  }

  &.mat-checkbox-disabled {
    opacity: 0.4;

    &.mat-checkbox-checked {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-frame {
            border-color: $yellow-orange;
          }

          .mat-checkbox-background {
            background-color: $yellow-orange;
          }
        }
      }
    }

    &:not(.mat-checkbox-checked) {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-frame {
            border-color: $mystic;
          }

          .mat-checkbox-background {
            background-color: rgba($mystic, 0.3);
          }
        }
      }
    }
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $cranberry;
}


.mat-mdc-checkbox {
  .mdc-form-field {
    width: 100%;

    label {
      flex: 1;
      padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 20px) / 2) 12px calc((var(--mdc-checkbox-state-layer-size, 40px) - 20px) / 2) 0;
      max-width: calc(100% - 40px);
      display: flex;
      align-items: center;

      .mat-checkbox-text {
        font-size: 13px;

        &:first-child:not(:last-child) {
          padding-right: 10px;
        }
      }

      .mat-checkbox-alternate {
        margin-left: auto;
        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;
        flex: 0 0 20px;
        max-width: 20px;
        text-align: center;
        background: $secondary-gradient;
        color: $ebony;
        height: 15px;
        line-height: 15px;
        border-radius: 1.5px;

        & + .mat-checkbox-suffix {
          margin-left: 6px;
        }
      }

      .mat-checkbox-suffix {
        margin-left: auto;
        margin-right: 0;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
