@import "~jsoneditor/dist/jsoneditor.min.css";
@import "src/assets/styles/vars/colors";

json-editor > div {
  height: 100%;
}

.in-modal {
  .jsoneditor-outer > .jsoneditor-tree {
    min-height: calc(100vh - 212px);
  }
}

mat-dialog-container {
  json-editor {
    height: calc(100% - 100px);
  }
}

div.jsoneditor-tree {
  background-color: $mirage;
}

.jsoneditor-popover,
.jsoneditor-schema-error,
div.jsoneditor td,
div.jsoneditor textarea,
div.jsoneditor th,
div.jsoneditor-field,
div.jsoneditor-value,
pre.jsoneditor-preview {
  color: $white;
}

.jsoneditor-menu {
  background-color: $bright-gray;
  border-color: $bright-gray;
  height: 40px;
  padding: 0 6px;
  display: flex;
  align-items: center;
}

.jsoneditor {
  color: $white;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

div.jsoneditor-outer {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}

.jsoneditor-navigation-bar {
  display: none !important;
}

div.jsoneditor-value.jsoneditor-number {
  color: $cranberry;
}

div.jsoneditor-value.jsoneditor-string,
a.jsoneditor-value.jsoneditor-url, div.jsoneditor-value.jsoneditor-url {
  color: $mountain-meadow;
}

a.jsoneditor-value.jsoneditor-url:focus, a.jsoneditor-value.jsoneditor-url:hover {
  color: $azure-radiance;
}

div.jsoneditor-value.jsoneditor-null {
  color: $sail;
}

div.jsoneditor-tree button.jsoneditor-button:focus {
  outline: none !important;
  background-color: transparent !important;
}

div.jsoneditor-tree button.jsoneditor-button {
  filter: invert(1);
}

.jsoneditor-search {
  top: 7px;
  right: 6px;
}

.jsoneditor-results {
  padding-right: 12px;
}

.jsoneditor-frame {
  background-color: $tuna;
  border-radius: 4px;
  padding: 0 4px;
}

.jsoneditor-search input {
  background-color: $tuna;
  border: none !important;
  padding: 0 4px !important;
  color: $white;
  height: 100% !important;
  margin: 0 !important;
  outline: none !important;
}

div.jsoneditor-field.jsoneditor-highlight-active,
div.jsoneditor-field.jsoneditor-highlight-active:focus,
div.jsoneditor-field.jsoneditor-highlight-active:hover,
div.jsoneditor-value.jsoneditor-highlight-active,
div.jsoneditor-value.jsoneditor-highlight-active:focus,
div.jsoneditor-value.jsoneditor-highlight-active:hover {
  background-color: $sunglow;
  color: $steel-gray;
}
