@import "src/assets/styles/vars/colors";

mat-progress-bar {
  &.mat-progress-bar{
    border-radius: 100px;
    height: 8px;

    .mat-progress-bar-background {
      fill: $mystic;
    }

    .mat-progress-bar-buffer {
      background-color: $mystic;
    }

    .mat-progress-bar-fill {
      border-radius: 100px;

      &:after {
        background: $primary-gradient;
      }
    }
  }
}
