@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/animations";
@import "src/assets/styles/vars/media";

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    transition: $base-transition;
    color: $color;
  }

  &::-moz-placeholder {
    transition: $base-transition;
    color: $color;
  }

  &:-ms-input-placeholder {
    transition: $base-transition;
    color: $color;
  }

  &:-moz-placeholder {
    transition: $base-transition;
    color: $color;
  }
}

@mixin fieldChangedState($color) {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        background: $white;
        color: $color;
      }
    }

    .mat-form-field-subscript-wrapper {
      .mat-form-field-hint-wrapper {
        mat-hint {
          color: $color;
        }
      }
    }
  }
}

.form-group {

  &.h-100 {
    mat-form-field {
      height: 100%;

      .mat-form-field-wrapper {
        height: 100%;

        .mat-form-field-flex {
          height: 100%;

          .mat-form-field-infix {
            height: 100%;

            textarea {
              height: 100%;
            }
          }
        }
      }
    }
  }

  &.no-gutters {
    mat-form-field {
      .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  mat-form-field {
    &.no-gutters {
      .mat-form-field-wrapper {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  &.disabled {
    mat-label {
      &:not(.ignore-disabled) {
        color: rgba($mine-shaft, 0.35);
      }
    }
  }

  mat-label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    margin-bottom: 8px;
    transition: $base-transition;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &[class^="has-icon"] {
      display: flex;
      align-items: center;
    }

    &.has-icon-right {
      mat-icon {
        margin-left: 6px;
      }
    }

    &.has-icon-left {
      mat-icon {
        margin-right: 6px;
      }
    }

    mat-icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      cursor: help;
    }

    &.secondary {
      opacity: 0.5;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 6px;

      mat-icon {
        width: 14px;
        height: 14px;
        font-size: 14px;
      }
    }
  }

  mat-form-field {
    width: 100%;
    color: $ebony;

    &.mat-form-field-appearance-outline {
      &.mat-form-field-bh {
        &.mat-form-field-error {
          .mat-form-field-wrapper {
            margin-bottom: 24px;
          }
        }

        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-infix {
              padding: 16.75px 0;

              input {
                font-size: 20px;
              }
            }
          }

          .mat-form-field-subscript-wrapper {
            overflow: visible;
          }
        }
      }

      .mat-form-field-wrapper {
        margin-top: 0;

        .mat-form-field-flex {
          margin-top: 0;

          .mat-form-field-outline {
            top: 0;
            transition: $base-transition;
            color: $white;
            background: $white;
            border-radius: 8px;
            opacity: 1 !important;

            .mat-form-field-outline-start {
              border-radius: 8px 0 0 8px;
            }

            /*.mat-form-field-outline-end {
              border-radius: 0 8px 8px 0;
            }*/

            &.mat-form-field-outline-thick .mat-form-field-outline-start,
            &.mat-form-field-outline-thick .mat-form-field-outline-end,
            &.mat-form-field-outline-thick .mat-form-field-outline-gap {
              border-width: 1px !important;
            }
          }

          .mat-form-field-infix {
            width: auto;
            border: none;
            padding: 15px 0;
            white-space: nowrap;

            @media screen and (max-width: $media-mobile-lg - 1px) {
              padding: 12px 0;
            }

            textarea {
              padding: 0;
              resize: none;
              min-height: 70px;
            }

            input, textarea {
              @include placeholder($mine-shaft);
              font-weight: 400;
              font-size: 16px;
              letter-spacing: 0.2px;
              margin: 0;
              caret-color: currentColor;

              @media screen and (max-width: $media-mobile-lg - 1px) {
                font-size: 14px;
              }
            }

            .mat-form-field-label-wrapper {
              display: none !important;
            }
          }

          .mat-form-field-suffix {
            top: 0;
            bottom: 0;
            margin: auto 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            > span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }

            mat-icon {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;

              &.success {
                color: $mountain-meadow;
              }
            }
          }

          .mat-form-field-prefix {
            top: 0;
            bottom: 0;
            margin: auto 12px auto 0;

            img, i {
              border-radius: 2px;
            }

            i {
              background-color: rgba($mine-shaft, 0.5);
            }
          }
        }

        .mat-form-field-subscript-wrapper {
          padding: 0;
          margin-top: 4px;

          .mat-form-field-hint-wrapper {
            mat-hint {
              font-family: 'Manrope', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.2px;
              color: $mine-shaft;
            }
          }
        }
      }


      &:not(.mat-form-field-disabled):not(.no-hover) {
        &.mat-form-field-success {
          @include fieldChangedState($mountain-meadow);
        }

        &.mat-form-field-error {
          @include fieldChangedState($pomegranate);
        }

        &.mat-form-field-warning {
          @include fieldChangedState($sunglow);
        }
      }

      &.mat-form-field-disabled, &.mat-field-readonly {
        &:not(.mat-form-transparent-field) {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              .mat-form-field-outline {
                background: rgba($tuna, 0.35) !important;
              }

              .mat-form-field-infix {
                input, textarea {
                  @include placeholder(rgba($ghost, 0.35));
                }
              }

              .mat-form-field-suffix {
                mat-icon {
                  opacity: 0.35;
                }
              }
            }
          }
        }
      }

      &.mat-form-field-medium {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-prefix {
              margin-right: 0;

              > * {
                &:not(:empty) {
                  margin-right: 8px;
                }
              }

              .mat-field-multiple-counter {
                background: $lynch;
                color: $white;
                font-size: 12px;
                font-weight: 500;
                padding: 4px 6px;
                border-radius: 4px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: help;
              }
            }

            .mat-form-field-infix {
              padding: 12px 0;

              input, textarea {
                font-size: 14px;
                letter-spacing: 0.2px;
              }
            }
          }
        }

        &.mat-form-filter-field {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              padding: 0 16px;
            }
          }
        }

        &.mat-form-datepicker {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              .mat-form-field-infix {
                padding: 10.88px 0;
                overflow: hidden;
              }
            }
          }
        }

        &.mat-form-datepicker-single {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              .mat-form-field-infix {
                padding: 11.88px 0;
                overflow: hidden;
              }
            }
          }
        }
      }

      &.mat-form-field-small {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-prefix {
              margin-right: 6px;
            }

            .mat-form-field-infix {
              padding: 9px 0;

              input, textarea {
                font-size: 14px;
                letter-spacing: 0.2px;
              }
            }
          }
        }
      }

      &.mat-form-filter-field {
        color: $white;

        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-outline {
              background: $tuna;

              &.mat-form-field-outline-thick .mat-form-field-outline-start,
              &.mat-form-field-outline-thick .mat-form-field-outline-end,
              &.mat-form-field-outline-thick .mat-form-field-outline-gap {
                border: none !important;
              }

              .mat-form-field-outline-start, .mat-form-field-outline-end {
                border: none !important;
              }
            }

            .mat-form-field-infix {
              input, textarea {
                @include placeholder(rgba($white, 0.35));
              }
            }
          }
        }
      }

      &.mat-form-transparent-field {
        color: $white;

        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-outline {
              background: transparent;

              &.mat-form-field-outline-thick .mat-form-field-outline-start,
              &.mat-form-field-outline-thick .mat-form-field-outline-end,
              &.mat-form-field-outline-thick .mat-form-field-outline-gap {
                border: none !important;
              }

              .mat-form-field-outline-start, .mat-form-field-outline-end {
                border: none !important;
              }
            }

            .mat-form-field-infix {
              input, textarea {
                @include placeholder(rgba($white, 0.35));
              }
            }
          }
        }
      }
    }
  }
}

.form-group {
  &.disabled {
    .form-phone-group {
      .country-group {
        opacity: 0.3;
      }
    }
  }

  .form-phone-group {
    display: flex;
    transition: $base-transition;

    .country-group {
      display: flex;
      align-items: center;

      img {
        & + mat-icon {
          margin-left: 6px;
        }
      }

      mat-icon {
        height: 20px;
        width: 20px;
      }
    }

    mat-form-field {
      &:nth-child(1) {
        flex: 0 0 auto;
        width: auto;
        max-width: 125px;

        .mat-form-field-wrapper {
          .mat-form-field-flex {
            padding-right: 0;

            .mat-form-field-outline {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }

      &:nth-child(2) {
        flex: 1 0 0;

        .mat-form-field-wrapper {
          .mat-form-field-flex {
            padding-left: 0;

            .mat-form-field-outline {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }

    .mat-icon-button {
      width: 48px;
      height: 48px;
      margin-left: 8px;
      border-radius: 8px;
    }
  }
}

mat-hint {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .2px;
  color: $mine-shaft;

  &.success {
    color: $mountain-meadow;
  }

  &.error {
    color: $pomegranate;
  }

  &.warning {
    color: $sunglow;
  }

}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-date-range-input-inner[disabled] {
  color: rgba($white, 0.6);
}

.mat-form-field-disabled .mat-form-field-prefix, .mat-form-field-disabled .mat-form-field-suffix {
  color: rgb(255 255 255 / 38%);
}

.mat-input-element {
  &.mat-readonly, &[readonly="true"] {
    color: rgba($white, 0.4);
  }
}

app-universal-select-box {
  .mat-input-element {
    &[readonly="true"] {
      color: $white;
    }

    &:disabled {
      color: rgba($white, 0.35);
    }
  }
}

.mat-mdc-form-field {
  width: 100%;

  &.mat-form-field-md {
    .mat-mdc-text-field-wrapper {
      border-radius: 8px;
      padding-left: 10.5px;
      padding-right: 10.5px;

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-icon-prefix, [dir=rtl] .mat-mdc-form-field-icon-suffix {
          padding: 0 8px 0 0;
        }

        .mat-mdc-form-field-infix {
          .mdc-text-field__input {
            height: 39.75px;
          }
        }
      }
    }
  }

  &.mat-form-field-no-bottom {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }

  &.mat-form-field-appearance-outline {

    &.mat-primary {
      .mat-mdc-text-field-wrapper {
        background-color: $tuna;
      }
    }

    &.mat-accent {
      .mat-mdc-text-field-wrapper {
        background-color: $steel-gray;
      }
    }

    &.mat-mdc-form-field-type-mat-select {
      .mat-mdc-text-field-wrapper {
        background-color: $tuna;
        border-radius: 6px;
        padding: 0;
        overflow: hidden;

        &:hover {
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
              background-color: rgba($white, 0.1);
            }
          }
        }

        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            background-color: rgba($white, 0);
            padding: 6px 8px 6px 12px;
            transition: 0.2s ease-in-out background-color;

            @media screen and (max-width: $media-tablet-sm - 1px) {
              padding: 4px 6px 4px 8px;
            }

            .mat-mdc-select {
              @media screen and (max-width: $media-tablet-sm - 1px) {
                font-size: 13px;
              }

              .mat-mdc-select-arrow-wrapper {
                margin-left: 8px;
                margin-right: 4px;

                @media screen and (max-width: $media-tablet-sm - 1px) {
                  margin-left: 6px;
                  margin-right: 2px;
                }
              }
            }
          }
        }
      }
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        align-items: center;

        .mat-mdc-form-field-infix {
          width: auto;

          .mdc-text-field__input {
            color: $white;
          }
        }

        .mdc-notched-outline {
          display: none !important;
        }

        .mat-mdc-form-field-icon-prefix > .mat-icon,
        .mat-mdc-form-field-icon-suffix > .mat-icon {
          padding: 0;
        }
      }
    }
  }
}

.mat-mdc-form-field {
  &.mat-mdc-form-field-md {
    .mat-mdc-text-field-wrapper {
      border-radius: 8px;
      padding: 0 10.5px;
      min-height: 39.75px;
      align-items: center;
    }

    .mat-mdc-form-field-bottom-align {
      display: none !important;
    }
  }

  .mat-mdc-chip-input {
    color: $white;
    caret-color: inherit;
    @include placeholder(rgba($ghost, 0.35));
  }
}

.mat-mdc-select-panel {
  background: $white;
  color: $ebony;
  padding: 0 !important;

  .mat-mdc-option {
    min-height: 36px;
    font-size: 13px;
    padding: 0 12px;

    &.mdc-list-item--selected {
      background: rgba($mine-shaft, 0.12);
    }

    &:not(.mdc-list-item--selected):hover {
      background: rgba($mine-shaft, 0.1);
    }

    .mdc-list-item__primary-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
    }
  }
}
