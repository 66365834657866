@import "src/assets/styles/vars/colors";

mat-accordion {
  &.mat-accordion-detailed-list {
    mat-expansion-panel {
      background-color: $tuna;
      color: $white;
      border-radius: 6px !important;

      & + mat-expansion-panel {
        margin-top: 16px !important;
      }

      &.mat-expansion-panel-spacing {
        margin: 0;
      }

      mat-expansion-panel-header {
        height: auto !important;
        padding: 12px 16px;
        background: none !important;

        mat-panel-title {
          color: $white;
          font-weight: 500;
          font-size: 14px;
        }

        mat-panel-description {
          color: $white;
          justify-content: end;
          font-size: 12px;
          margin-right: 12px;
        }

        .mat-expansion-indicator {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;

          &:after {
            color: rgba($white, 0.7);
            margin-top: -4px;
          }
        }
      }

      .mat-expansion-panel-body {
        padding: 0 16px 12px;
      }
    }
  }
}
