@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

.mat-autocomplete-panel {
  background-color: $tuna;
  color: $white;
  box-shadow: 0 10px 25px rgba($mine-shaft, 0.65) !important;
  border-radius: 8px !important;
  min-width: auto !important;
  margin: 6px 0;

  mat-option {
    color: inherit;

    &:hover:not(.mat-option-disabled), &:focus:not(.mat-option-disabled) {
      background: $bright-gray;
    }

    &.mat-selected {
      background: $secondary-gradient !important;
    }
  }

  cdk-virtual-scroll-viewport {
    overflow-x: hidden;
  }

  &.mat-autocomplete-select-box {
    background: $bright-gray;
    padding: 8px;

    .mat-option {
      line-height: 1;
      height: auto;
      min-height: 37px;
      padding: 6px 8px;
      border-radius: 8px;

      &:hover:not(.mat-option-disabled), &:focus:not(.mat-option-disabled) {
        background: $steel-gray;
      }

      .mat-option-text {
        display: inline-flex;
        align-items: center;

        flagpack-icon {
          margin-right: 10px;
          flex: 0 0 20px;

          & + span {
            max-width: calc(100% - 59px);
            padding-right: 12px;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: inherit;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;

          &:nth-of-type(2) {
            margin-left: auto;
          }
        }

        mat-checkbox {
          margin-right: -11px;
        }
      }
    }
  }

  &.mat-autocomplete-small {
    .mat-option {
      line-height: 40px;
      height: 40px;
      font-size: 14px;
      padding: 0 12px;
    }
  }

  &:not(.mat-autocomplete-small) {
    .mat-option {
      line-height: 58px;
      height: 58px;

      @media screen and (max-width: $media-mobile-lg - 1px) {
        line-height: 46px;
        height: 46px;
        font-size: 15px;
      }
    }
  }

  .mat-option {
    .mat-option-text {
      small {
        font-weight: 500;
        opacity: 0.7;
        margin-left: 3px;

        &:before {
          content: '•';
          margin-right: 5px;
        }
      }
    }

    &.mat-option-disabled {
      color: $waterloo;

      .option-loader {
        opacity: 0.6;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        width: 100%;

        &:before {
          content: '';
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATcAAAABCAYAAAC7duk2AAAAAXNSR0IArs4c6QAAAZVJREFUOE91kzvOqzAQhcdtEh5BRCgSRYpUabMBNsF6EOthE2yAlooiBRJCIN7Q5teMbOvOtXKaI38+lvEwI4BL4PL7/TKapinxJEkYz7KMeBzHjOd5TjyKIsaLoiD+fr8ZL8uS+Ov1YryqKuLP5/O/zwT4fD7i8XigA7pSXdciDEMj3zSNuN/v0DQNoCu1bSuCIDDyXdeJ2+0GXdcBulLf98L3fSM/DIPwPA+GYQB0pXEc6Q2o6/Wq+TRNwnVdmKYJ0JXmedZ5x3E0X5ZF2LYNy7IAutK6rjpvWRbjuF7XFf7lGNi2jc5cLhf2jl9833fKn89nlv/Fj+Og/Ol0MuqkwHEctC9dN9y+73SPdN6IAAbfto3eId3I/+JYF5RlWezML451R9m2zfLzPBN3HIdx/K8o13UZH8dR9QLj2Dcoz/MY7/ueuO/7jKu+lK732rbVNQ+CQHPV99I1r+ta58MwNOon50vzqqpoHqUb+bIscY41L4qC5l26kc/znO6PoojtZVlGPI5jxtM0JZ4kCeNC6DHQ/A8f9tYC6XsEggAAAABJRU5ErkJggg==');
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          top: 0;
        }

        mat-spinner {
          margin-right: 6px;
        }
      }
    }

    &.disable-default-behavior {
      cursor: default;

      &:hover, &:focus {
        &:not(.mat-option-disabled), &:not(:disabled), &:not(.mat-selected) {
          background-color: $white;
        }
      }

      .mat-ripple {
        display: none !important;
      }
    }

    .radio-inline-option {
      display: flex;
      align-items: center;

      mat-radio-button {
        display: inline-flex;
      }

      .typography-body {
        &.subheading-1 {
          color: $mine-shaft;
          margin-left: auto;
          padding-left: 10px;
        }
      }
    }

    .mat-option-disabled {
      color: $manatee;
    }
  }
}

.cdk-overlay-pane {
  .mat-autocomplete-panel {
    &:not(.mat-autocomplete-small) {
      border-radius: 10px !important;
    }
  }
}
