@import "src/assets/styles/vars/media";

.row {
  &.single-view-grid {
    > .col-3 {
      @media screen and (max-width: $media-desktop-lg - 1px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      @media screen and (max-width: $media-desktop-xs - 1px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media screen and (max-width: $media-tablet-sm - 1px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    > .col-4 {
      @media screen and (max-width: $media-desktop-lg - 1px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media screen and (max-width: $media-desktop-xs - 1px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    > .col-6 {
      @media screen and (max-width: $media-desktop-lg - 1px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      @media screen and (max-width: $media-desktop-xs - 1px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &.gap-12 {
    @media screen and (max-width: $media-mobile-lg - 1px) {
      > [class^=col] {
        & + [class^=col] {
          padding-top: 0 !important;
        }
      }
    }
  }
}
