@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/animations";
@import "src/assets/styles/vars/media";

.mat-date-range-input {
  overflow: hidden;

  &:not(.no-prefix) {
    width: 230px !important;
  }

  &.justify-inputs {
    .mat-date-range-input-wrapper {
      &:first-child {
        input {
          text-align: left;
        }
      }

      &:last-child {
        input {
          text-align: right;
        }
      }
    }
  }
}

.mat-form-datepicker {
  &:not(.mat-form-field-disabled){
    cursor: pointer;
  }

  .mat-date-range-input-start-wrapper, .mat-date-range-input-end-wrapper {
    display: flex;
    align-items: center;

    .mat-date-range-input-mirror {
      display: none !important;
    }
  }

  .mat-date-range-input-container {
    justify-content: space-between;
  }

  .mat-date-range-input-wrapper {
    flex: 0 0 50%;
    max-width: calc(50% - 11.875px);

    .mat-date-range-input-inner {
      position: static;
      width: 100%;
      text-align: center;
    }
  }

  .mat-form-field-flex {
    .mat-form-field-prefix {
      @media screen and (max-width: 379px) {
        margin-right: 0 !important;

        mat-icon {
          margin-right: 0 !important;
        }

        .text-label {
          display: none !important;
        }
      }
    }
  }
}

mat-datepicker-content {
  &[style*="transform-origin: left top"] {
    margin-top: 8px;
  }

  &[style*="transform-origin: left bottom"] {
    margin-bottom: 8px;
  }

  &.mat-datepicker-content {
    background: $tuna;
    color: $white;
    border-radius: 6px !important;

    .mat-datepicker-content-container {
      .mat-calendar {
        width: 290px;
        height: auto;

        .mat-calendar-content {
          padding: 0;

          table {
            .mat-calendar-table-header {
              th {
                padding: 14px 0;
                font-size: 16px;
                text-transform: lowercase;
                color: rgba($white, 0.7);

                &.mat-calendar-table-header-divider {
                  display: none;
                }
              }
            }

            tbody {
              tr[aria-hidden="true"] {
                &:first-child {
                  display: none !important;
                }
              }

              .mat-calendar-body-cell-content {
                color: rgba($white, 0.87);
              }

              .mat-calendar-body-disabled {
                cursor: default !important;

                .mat-calendar-body-cell-content {
                  color: rgba($white, 0.2);
                }
              }

              .mat-calendar-body-active {
                font-weight: 700 !important;
              }

              .mat-calendar-body-in-range {
                &:before {
                  background-color: rgba($martinique, 0.85);
                }
              }
            }
          }
        }

        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          border: none;
          font-weight: 700;
        }

        .mat-calendar-body-today.mat-calendar-body-selected {
          box-shadow: none;
        }

        .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          background-color: rgba($mirage, 0.5);
        }

        .mat-calendar-body-in-range::before {
          background-color: rgba($martinique, 0.85);
        }

        .mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before, .mat-calendar-body-range-start::after, .mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before, .mat-calendar-body-comparison-start::after, .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
          width: 100%;
        }

        .mat-calendar-body-cell-content {
          width: 100%;
          height: 100%;
          border: none;
          top: 0;
          left: 0;
        }

        .mat-calendar-body-cell::before, .mat-calendar-body-cell::after, .mat-calendar-body-cell-preview {
          height: 100%;
          top: 0;
          left: 0;
          width: 100%;
        }

        .mat-calendar-body-in-preview {
          &:hover {
            .mat-calendar-body-cell-content {
              background-color: rgba($martinique, 0.87) !important;
              color: $white !important;
            }
          }

          .mat-calendar-body-cell-preview {
            border: none !important;
            background-color: rgba($martinique, 0.85);
          }
        }

        .mat-calendar-table-header-divider::after {
          content: none !important;
        }

        .mat-datepicker-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 12px 0;

          .mat-button-base.mat-raised-button {
            padding: 0;
            min-width: auto;
          }

          .mat-datepicker-header-selections {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;

            .form-group {
              flex: 1 0 auto;

              & + .form-group {
                margin-left: 12px;
              }
            }
          }
        }

        .mat-calendar-body-label {
          opacity: 0 !important;
          visibility: hidden !important;
        }
      }

      .mat-datepicker-actions {
        display: block;
        padding: 0;

        .mat-datepicker-actions-top {
          .mat-button-base.mat-raised-button {
            font-size: 14px;
            width: 100%;
            border-radius: 0;
          }
        }

        .mat-datepicker-actions-bottom {
          display: flex;
          align-items: center;
          padding: 12px;

          button {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }
    }
  }
}

.mat-form-datepicker {
  height: 100%;

  &.mat-datepicker-has-value {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-prefix, .mat-form-field-suffix {

          mat-datepicker-toggle {
            button {
              svg {
                path {
                  fill: rgba($white, 0.87);
                }
              }
            }
          }

          span {
            color: rgba($white, 0.87);
          }
        }
      }
    }
  }

  .mat-date-range-input-separator {
    color: inherit;
  }

  .mat-form-field-wrapper {
    padding: 0;
    height: 100%;

    .mat-form-field-flex {
      height: 100%;
      align-items: center;

      .mat-form-field-prefix, .mat-form-field-suffix {
        height: 100%;
        display: flex;

        mat-datepicker-toggle {
          display: flex;
          align-items: center;

          button {
            margin-right: 5px;
          }
        }

        span {
          display: flex;
          align-items: center;
          color: rgba($white, 0.87);
          transition: $base-transition;
        }
      }
    }
  }
}

@media screen and (max-width: $media-tablet-sm) {
  .cdk-overlay-backdrop {
    &.mat-datepicker-0-backdrop {
      background: rgba($ebony, 0.7);
      backdrop-filter: blur(4px);

      & + .cdk-overlay-connected-position-bounding-box {
        .cdk-overlay-pane {
          &.mat-datepicker-popup {
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;

            mat-datepicker-content {
              height: max-content;
              transform-origin: center !important;
            }
          }
        }
      }
    }
  }
}
