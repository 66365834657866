@import "src/assets/styles";
@import "src/assets/styles/mixins/scrollbar";
@import "src/assets/styles/vars/media";

* {
  @include scrollbar($martinique, $white);
}

body, html {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: $white;
  background-color: $ebony;
  word-break: break-word;
  overflow-x: hidden;

  &.overflow-hidden {
    overflow: hidden !important;

    @media screen and (min-width: $media-desktop-xs) {
      padding-right: 6px;
    }
  }
}

button, a {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.position-relative {
  position: relative !important;
}

.m-0 {
  margin: 0 !important;
}

.list-unstyled {
  list-style: none !important;
  padding-left: 0 !important;

  > li, ol {
    list-style-type: none !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 500 !important;
}

.primary-text-color {
  color: $yellow-orange !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.text-truncate {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.page-title-suffix {
  font-size: 80%;
  opacity: .7;
  font-weight: 500
}

.page-title-delimiter {
  opacity: .7;
  font-weight: 500
}

.page-title-link {
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: $white;
  text-decoration: none;
  transition: $base-transition;
  font-weight: 500;

  &:hover {
    color: $yellow-orange;
    opacity: 1;
  }
}

input:placeholder-shown {
  text-overflow: ellipsis !important;
}

app-comment-item + app-comment-item {
  margin-top: 12px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.justify-content-start {
  justify-content: start !important;
}
