@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  box-shadow: 0 10px 25px rgba($mine-shaft, 0.65) !important;
  border-radius: 0.42rem !important;
  min-height: 48px !important;
  margin: 6px 0;
  background: $bright-gray;

  &.multiply-box-menu {
    background: $bright-gray;
    width: 300px;
    min-width: 250px;
    max-width: 100vw;

    @media screen and (max-width: $media-desktop-xs - 1px) {
      transform-origin: center center;
      width: 350px;
    }

    @media screen and (max-width: $media-mobile-md - 1px) {
      margin: 0;
      width: 100vw;
      height: 100vh;
      max-height: 100%;
      border-radius: 0 !important;
    }

    .mat-mdc-menu-content {
      padding: 0;

      @media screen and (max-width: $media-mobile-md - 1px) {
        height: 100%;
      }
    }
  }
}

.mat-menu-panel {
  background: $bright-gray;
  box-shadow: 0 10px 25px rgba($mine-shaft, 0.65) !important;
  border-radius: 0.42rem !important;
  min-height: 48px !important;

  &.language-switcher {
    min-width: auto !important;

    button {
      &.mat-menu-item {
        height: 36px;
        line-height: 36px;
        font-size: 15px;
      }
    }
  }

  @media screen and (min-width: $media-tablet-sm) {
    min-width: 120px !important;
  }

  &.mat-menu-below {
    margin-top: 6px;
  }

  &.mat-menu-above {
    margin-bottom: 6px;
  }

  button {
    &.mat-menu-item {
      padding: 0 12px !important;
      color: $white;
      height: 32px;
      line-height: 32px;
      font-size: 13px;

      .mat-ripple-element {
        background-color: rgba($white, 0.08) !important;
      }

      &:not(:disabled) {
        &:hover {
          background-color: rgba($white, 0.08);
        }
      }

      &:disabled {
        opacity: 0.4;
      }

      img {
        margin-right: 12px;

        + span {
          font-weight: 500;
        }
      }
    }
  }
}
