@import "src/assets/styles/vars/media";

.mobile-md-min-visibility {
  @media screen and (min-width: $media-mobile-md) {
    display: none !important;
  }
}

.mobile-md-ml-auto {
  @media screen and (max-width: $media-mobile-md - 1px) {
    margin-left: auto !important;
  }
}

.mobile-md-mr-auto {
  @media screen and (max-width: $media-mobile-md - 1px) {
    margin-right: auto !important;
  }
}
