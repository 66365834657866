@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/animations";

@mixin paginationArrow($arrowDisabled, $arrowActive) {
  &.disabled {
    &:before {
      content: '' !important;
      background-image: url($arrowDisabled);
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 !important;
      width: 8px;
      height: 8px;
      display: inline-block;
    }

    &:after {
      content: none !important;
    }
  }

  &:not(.disabled) {
    > a {
      &:before {
        content: '' !important;
        background-image: url($arrowActive);
        background-position: center;
        background-repeat: no-repeat;
        margin: 0;
        width: 8px;
        height: 8px;
        display: inline-block;
      }

      &:after {
        content: none !important;
      }
    }
  }

  span {
    display: none !important;
  }
}

pagination-controls {
  ul {
    &.ngx-pagination {
      font-family: 'Montserrat', sans-serif;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      > li {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        padding: 0 !important;

        &.disabled {
          pointer-events: none;
        }

        &.pagination-previous {
          @include paginationArrow(
              "data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.625 11.25L1.375 6L6.625 0.75' stroke='%235F7390' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A",
              "data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.625 11.25L1.375 6L6.625 0.75' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"
          );
        }

        &.pagination-next {
          @include paginationArrow(
              "data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.375 0.75L6.625 6L1.375 11.25' stroke='%235F7390' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A",
              "data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.375 0.75L6.625 6L1.375 11.25' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"
          );
        }

        &.current {
          padding: 0;
          background: $secondary-gradient;
          color: $ebony;
          border-radius: 4px;
        }

        > a {
          padding: 0;
          text-decoration: none !important;
        }

        > a, button {
          border-radius: 4px;
          transition: $base-transition;
          color: $white;

          &:hover {
            background-color: rgba($white, 0.1);
          }
        }
      }
    }
  }
}
