@import "src/assets/styles/vars/colors";

mat-bottom-sheet-container {
  &.mat-bottom-sheet-container {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 0;
    overflow-x: hidden;

    .bottom-sheet-header {
      border-bottom: 1px solid $mystic;
      padding: 16px 26px;

      h3 {
        font-weight: 500 !important;
      }

      button {
        height: 30px !important;
        padding: 0 !important;
        min-width: 30px !important;

        mat-icon {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    .bottom-sheet-body {
      padding: 26px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .bottom-sheet-footer {
      padding: 6px 26px 24px;
    }
  }
}
