@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;

  body {
    overflow-y: hidden;

    @media screen and (min-width: $media-desktop-xs) {
      padding-right: 6px;
    }
  }
}

.cdk-overlay-pane {
  mat-dialog-container {
    &.mat-dialog-container {
      background: $steel-gray;
      color: $white;
      border-radius: 16px;
      padding: 24px 32px;
      position: relative;
      overflow: hidden !important;
      margin: 16px;
      height: auto;
      box-shadow: none !important;

      app-json-editor-modal {
        display: block;
        height: 100%;

        .mat-dialog-content {
          height: 100%;
          max-height: 100%;
        }
      }

      @media screen and (max-width: $media-tablet-sm - 1px) {
        margin: 12px;
        padding: 20px;
      }

      .mat-dialog-header-row {
        margin-bottom: 16px;

        .mat-dialog-title {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.2;
        }
      }

      .mat-dialog-footer-row {
        margin-top: 8px;
      }

      button {
        &.mat-button {
          &.dialog-close-icon-button {
            height: 36px;
            padding: 0;
            min-width: 36px;

            mat-icon {
              margin: 0 auto !important;
              width: 24px !important;
              height: 24px !important;
            }
          }
        }
      }

      .mat-dialog-content {
        margin: 0 -8px !important;
        padding: 0 8px !important;
        overflow-x: hidden !important;
        max-height: 100%;

        form {
          app-flat-toast {
            margin-bottom: 16px;
          }
        }
      }
    }
  }


}

.mdc-dialog {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      background: $steel-gray;
      position: relative;
      margin: 16px;
      border-radius: 16px;
      max-width: calc(100% - 32px);
      max-height: calc(100vh - 32px);

      .mat-mdc-dialog-content {
        padding: 24px 32px;
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $media-tablet-sm - 1px) {
          padding: 20px;
        }

        @media screen and (max-width: $media-mobile-lg - 1px) {
          padding: 16px;
        }

        &.mdc-dialog__content {
          --mdc-dialog-supporting-text-color: $white;
          --mdc-dialog-supporting-text-font: 'Montserrat';
          --mdc-dialog-supporting-text-weight: 400;
          --mdc-dialog-supporting-text-tracking: 0;
          --mdc-dialog-supporting-text-line-height: 1.6;

          .mat-dialog-header-row {
            .mdc-dialog__title {
              --mdc-dialog-subhead-color: $white;
              --mdc-dialog-subhead-font: 'Montserrat';
              --mdc-dialog-subhead-weight: 500;
              --mdc-dialog-subhead-size: 18px;
              --mdc-dialog-subhead-tracking: 0;
              --mdc-dialog-subhead-line-height: 1.2;

              &:before {
                content: none;
              }

              padding: 0;
              margin: 0;
            }
          }

          .content {
            max-height: fit-content;
            overflow-x: hidden;
            overflow-y: auto;
            margin: 24px 0;
            background: $tuna;
            padding: 24px;
            border-radius: 8px;

            @media screen and (max-width: $media-tablet-sm - 1px) {
              padding: 20px;
              margin: 20px 0;
              max-height: fit-content;
            }

            > * {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        button {
          &.mat-button {
            &.dialog-close-icon-button {
              height: 36px;
              padding: 0;
              min-width: 36px;

              mat-icon {
                margin: 0 auto !important;
                width: 24px !important;
                height: 24px !important;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-fullscreen {
  @media screen and (max-width: $media-tablet-sm - 1px) {
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;

    .mdc-dialog {
      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          margin: 0;
          max-width: 100%;
          border-radius: 0;
          width: 100vw;
          max-height: 100%;

          .mat-mdc-dialog-content {
            &.mdc-dialog__content {
              .mat-dialog-header-row {
                .mdc-dialog__title {
                  --mdc-dialog-subhead-line-height: 1.2
                }
              }

              .content {
                max-height: calc(100% - 72px);
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
