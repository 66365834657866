@import "src/assets/styles/vars/media";
@import "src/assets/styles/vars/colors";

@mixin noDataOverlay {
  .no-data-overlay {
    overflow: hidden !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: rgba($steel-gray, 0.85);
    z-index: 101;

    > mat-icon {
      width: 72px;
      height: 72px;
      margin-bottom: 16px;
    }

    > h3 {
      text-align: center;
      font-size: 15px;

      @media screen and (max-width: $media-mobile-lg - 1px) {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}
