@import "src/assets/styles/vars/media";
@import "src/assets/styles/vars/colors";
@import "src/assets/styles/mixins/skeleton";

.h-100 {
  > .content-box {
    height: 100%;
  }
}

.content-box {
  background-color: $steel-gray;
  padding: 16px 24px;
  border-radius: 6px;

  @media screen and (max-width: $media-desktop-xs - 1px) and (min-width: $media-tablet-lg) {
    padding: 14px 20px;
  }

  @media screen and (max-width: $media-tablet-sm - 1px) {
    padding: 12px 16px;
  }

  @media screen and (min-width: $media-tablet-sm) {
    &.min-height {
      min-height: calc(100vh - 182px);

      app-flat-toast + .create-entity-form {
        min-height: calc(100vh - 302px);
      }

      .create-entity-form {
        min-height: calc(100vh - 252px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding: 24px 0;
      }
    }
  }

  &.abstract-corners {
    position: relative;
    overflow: hidden;

    .content-box-header, .content-box-body {
      position: relative;
      z-index: 3;
    }

    > * {
      position: relative;
      z-index: 3;
    }

    &:before {
      content: '';
      background: url("/assets/images/gradient-triangles.svg") no-repeat center/cover;
      width: 299px;
      height: 140px;
      position: absolute;
      right: 24px;
      top: 24px;
    }

    &:after {
      content: '';
      background: url("/assets/images/gradient-triangles-2.svg") no-repeat center/cover;
      width: 299px;
      height: 140px;
      position: absolute;
      left: 24px;
      bottom: 24px;
    }
  }

  &-header {
    & + .content-box-body {
      margin-top: 16px;
    }

    > .row {
      margin-bottom: -4px;
    }

    .content-box-caption {
      font-weight: 600;
      margin-bottom: 0;

      &_details {
        font-size: 12px;
        color: $manatee;
        font-weight: 500;
        line-height: 1.3;

        &.warn {
          &:after{
            content: '*';
            color: $chilean-fire;
          }
        }

        > span {
          > span {
            margin-left: 5px;
          }
        }

        &.loading {
          > span {
            > span {
              @include skeleton($bright-gray);
              display: inline-block;
              height: 12px;
              width: 80px;
            }
          }
        }
      }
    }
  }

  &-body {
    .content-box-header {
      margin-bottom: 10px;
    }

    & + .content-box-footer {
      margin-top: 16px;
    }

    .body-caption {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  ul.content-list-view {

    &.indicator-list {
      position: relative;
      padding: 6px 0;

      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        left: 12px;
        top: 0;
        border-radius: 2px;
        background: rgba($sail, 0.5);
      }
    }

    &.loading {
      li {
        .list-item-text {
          span {
            &:nth-child(2) {
              @include skeleton($bright-gray);
              display: inline-block;
              height: 12px;
            }
          }
        }
      }
    }

    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 16px;
      }

      &.no-data {
        .list-item-text {
          > span {
            &:nth-child(2) {
              color: $manatee;
              font-size: 12px;
            }
          }
        }
      }

      &.success {
        .icon-wrapper {
          &.round {
            background-color: $mountain-meadow;
          }
        }
      }

      &.error {
        .icon-wrapper {
          &.round {
            background-color: $pomegranate;
          }
        }
      }

      .icon-wrapper {
        &.square {
          flex: 0 0 40px;
          max-width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          background: rgba($sail, 0.1);
          overflow: hidden;

          > i {
            width: 30px;
            height: 30px;
          }
        }

        &.round {
          flex: 0 0 27px;
          max-width: 27px;
          height: 27px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 6px solid $steel-gray;
          position: relative;
          z-index: 1;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: $steel-gray;
            width: 9px;
            height: 9px;
            border-radius: 50%;
          }
        }
      }

      .list-item-text {
        padding-left: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.value-first {
          max-width: calc(100% - 12px);

          > span {
            display: block;
            font-weight: 500;
            line-height: 1.2;

            &:nth-child(2) {
              font-size: 12px;
              color: $manatee;
              margin-top: 2px;
            }
          }
        }

        &:not(.value-first) {
          max-width: calc(100% - 88px);

          &.no-actions {
            max-width: calc(100% - 40px);
          }

          > span {
            display: block;
            font-weight: 500;

            &:nth-child(1) {
              font-size: 12px;
              color: $manatee;
              line-height: 1.2;
            }

            &:nth-child(2) {
              max-width: 100%;
              white-space: nowrap;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 13px;
            }

            .tag {
              font-size: 11px;
              padding: 2px 6px;
              letter-spacing: 0.2px;

              & + .tag {
                margin-left: 6px;
              }
            }
          }
        }
      }

      .list-item-actions {
        padding-left: 12px;
      }
    }
  }
}

.single-view-grid {
  &.row {
    > * {
      > * {
        & + * {
          margin-top: 24px;
        }
      }
    }
  }
}
