@import "src/assets/styles/vars/animations";
@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

button, a {
  &.mat-mdc-icon-button {
    &.mat-button-md {
      height: 36px;
      width: 36px;
      padding: 0;
    }
  }

  &.mdc-button {
    white-space: nowrap;

    &.dialog-close-icon-button {
      height: 36px;
      padding: 0;
      min-width: 36px;

      mat-icon {
        margin: 0 !important;
      }
    }

    &.mat-button-sm {
      border-radius: 6px;
      height: 36px;
      font-weight: 500;
      font-size: 13px;
      padding: 0 12px;
    }

    &.mat-accent {
      background: $secondary-gradient;
      color: $ebony;
    }

    &.mat-primary {
      background: $primary-gradient;
      color: $white;
    }

    &.filter-trigger-btn {
      background-color: $tuna;
      color: $white;
      height: 39.75px;
      border-radius: 8px;
      padding: 0 16px;
      --mat-mdc-button-ripple-color: #{rgba($white, 0.1)};
      --mat-mdc-button-persistent-ripple-color: #{rgba($white, 0.04)};
      transition: $base-transition;

      @media screen and (max-width: $media-mobile-md - 1px) {
        min-width: auto !important;
      }

      &[aria-expanded="true"] {
        background-color: $bright-gray;
      }

      .mat-mdc-button-persistent-ripple {
        &:before {
          transition: inherit;
        }
      }

      &:hover {
        .mat-mdc-button-persistent-ripple {
          &:before {
            opacity: 1;
          }
        }
      }

      .mdc-button__label {
        font-size: 14px;
        letter-spacing: 0.2px;
        display: inline-flex;
        align-items: center;
        width: 100%;

        .multiply-box-prefix {
          margin-right: 8px;
          margin-left: -6px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .multiply-box-prefix {
          & + .multiply-box-text {
            max-width: calc(100% - 50px);
          }
        }

        .multiply-box-text {
          flex: 1 0 auto;
          text-align: left;
          max-width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;

          &.multiply-box-placeholder {
            color: rgba($ghost, 0.35);
          }
        }

        .multiply-box-suffix {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 6px;
          margin-right: -6px;
        }
      }
    }
  }

  &:not(.mdc-button) {
    &.filter-trigger-btn {
      background-color: $tuna;
      color: $white;
      height: 39.75px !important;
      border-radius: 8px !important;
      padding: 0 16px !important;
      --mat-mdc-button-ripple-color: #{rgba($white, 0.1)};
      --mat-mdc-button-persistent-ripple-color: #{rgba($white, 0.04)};
      transition: $base-transition;
      font-size: 14px !important;

      @media screen and (max-width: $media-mobile-md - 1px) {
        min-width: auto !important;
      }

      &[aria-expanded="true"] {
        background-color: $bright-gray;
      }

      .mat-mdc-button-persistent-ripple {
        &:before {
          transition: inherit;
        }
      }

      &:hover {
        .mat-mdc-button-persistent-ripple {
          &:before {
            opacity: 1;
          }
        }
      }

      .mdc-button__label {
        font-size: 14px;
        letter-spacing: 0.2px;
        display: inline-flex;
        align-items: center;
        width: 100%;
      }
    }
  }

  &:not(.mat-icon-button) {
    &.mat-button-base {
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        line-height: 1;
      }

      .mat-button-ripple {
        transition: $base-transition;
      }

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }

      &.small-copy {
        height: 16px !important;
        color: $white;
        border-radius: 4px;
        padding: 0 4px !important;
        min-width: auto;
        max-width: 100%;

        .mat-button-wrapper {
          max-width: 100%;

          > span {
            max-width: calc(100% - 16px);
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2;
          }
        }

        .value-button {
          font-size: 12px;
          color: $white;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        mat-icon {
          width: 12px !important;
          height: 12px !important;
          margin-left: 4px !important;
        }
      }

      &.grid-action {
        min-width: 40px;
        height: 40px !important;
        padding: 0 !important;
        border-radius: 6px !important;

        mat-icon {
          width: 22px !important;
          height: 22px !important;
          font-size: 22px !important;
        }

        .corner-counter {
          position: absolute;
          top: 0;
          right: 0;
          background: $primary-gradient;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.geo-button {
        width: auto !important;
        height: auto !important;
        padding: 4px !important;
        min-width: auto !important;
        border-radius: 4px !important;

        &:hover {
          background-color: rgba($lynch, 0.4);
        }
      }

      &.tag {
        width: auto !important;
        height: auto !important;
        min-width: auto !important;
        font-weight: 500 !important;
        font-size: 13px !important;
        padding: 4px 8px !important;
        border-radius: 4px !important;
        max-width: 100% !important;

        .mat-button-wrapper {
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;

          mat-icon {
            width: 14px !important;
            height: 14px !important;
            font-size: 14px !important;
            margin-left: -2px !important;
            margin-top: -2px;

            &[svgicon="search"] {
              width: 16px !important;
              height: 16px !important;
              font-size: 16px !important;
            }
          }
        }
      }

      &.mat-menu-button {
        height: 56px !important;
        width: 100%;
        border: none;
        color: inherit;
        padding: 0 24px !important;
        border-radius: 0;
        justify-content: start !important;

        .mat-button-wrapper {
          width: 100%;
        }
      }

      &.has-spinner, &.has-icon {
        &-right {
          mat-spinner {
            margin-left: 4px;
          }
        }

        &-left {
          mat-spinner {
            margin-right: 4px;
          }
        }
      }

      &.field-action-button {
        height: 48px !important;
      }

      &:not(.small) {
        height: 46px;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.3px;
        padding: 0 24px;

        @media screen and (max-width: $media-desktop-md) {
          padding: 0 20px;
        }

        &.form-square-icon-button {
          padding: 0;
          min-width: 39.75px;
          height: 39.75px;
          justify-content: center;
          justify-items: center;

          mat-icon {
            margin: 0 auto !important;
          }

          .loader--over {
            padding-left: 0 !important;
            margin-left: 0 !important;
          }
        }

        &.square-icon-button {
          padding: 0;
          min-width: 36px;
          height: 36px;
          justify-content: center;
          justify-items: center;

          mat-icon {
            margin: 0 auto !important;
          }
        }

        .mat-button-wrapper {
          mat-icon {
            width: 20px;
            height: 20px;
            font-size: 20px;
          }
        }
      }

      &.form-action {
        background: $tuna;
        height: 39.75px !important;
        width: 39.75px !important;
        padding: 0 !important;
        border-radius: 8px !important;
        min-width: auto !important;
      }

      &.link-button {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.005em;
        color: $manatee;
        height: auto;
        padding: 4px 6px;

        &.responsive {
          @media screen and (max-width: $media-tablet-sm - 1px) {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      &.has-icon-right {
        .mat-button-wrapper {
          mat-icon {
            margin-left: 8px;
          }
        }
      }

      &.has-icon-left {
        .mat-button-wrapper {
          mat-icon {
            margin-right: 8px;
            margin-left: -8px;
          }
        }
      }

      &.small {
        height: 40px;
        padding: 0 24px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.3px;

        @media screen and (max-width: $media-desktop-md) {
          padding: 0 20px;
        }

        &.square-icon-button {
          padding: 0 !important;
          min-width: 30px !important;
          border-radius: 0.42rem !important;
          height: 30px !important;

          mat-icon {
            margin: 0 auto !important;
          }
        }

        .mat-button-wrapper {
          mat-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
          }
        }
      }

      &.extra-small {
        height: 32px;
        border-radius: 0.42rem;
        padding: 0 13px;
        font-size: 13px;
        letter-spacing: 0;

        &.has-icon-left {
          .mat-button-wrapper {
            mat-icon, mat-spinner {
              margin-right: 6px;
              margin-left: -4px;
            }
          }
        }

        &.square-icon-button {
          padding: 0;
          min-width: 22px;
          height: 22px;

          mat-icon {
            margin: 0 auto !important;
          }
        }
      }

      &.mat-primary {
        background: $primary-gradient;
        color: $white;

        mat-spinner {
          color: $white;
        }
      }

      &.mat-accent {
        background: $secondary-gradient;
        color: $ebony;

        mat-spinner {
          color: $ebony;
        }
      }

      &.breadcrumbs-action {
        background: $bright-gray;
        color: $white;

        mat-spinner {
          color: $white;
        }
      }

      &.mat-warn {
        background: $pomegranate;
        color: $white;

        mat-spinner {
          color: $white;
        }
      }

      &.mat-success {
        &.mat-flat-button {
          background-color: $mountain-meadow;
          color: $white;
          box-shadow: none !important;

          &:not(:disabled):hover {
            .mat-button-ripple {
              background: rgba($white, 0.08);
            }
          }
        }
      }

      &.mat-info {
        &.mat-flat-button {
          background-color: $azure-radiance;
          color: $white;
          box-shadow: none !important;

          &:not(:disabled):hover {
            .mat-button-ripple {
              background: rgba($white, 0.08);
            }
          }
        }
      }

      &.mat-action {
        &.mat-flat-button {
          background-color: $azure-radiance;
          color: $white;
          box-shadow: none !important;

          .mat-button-wrapper {
            width: 100%;

            > span {
              margin: 0 auto;
            }

            mat-icon {
              width: 40px;
              height: 40px;
              margin-left: auto;
              margin-right: -20px;
              border-left: 1px solid rgba($white, 0.5);
            }
          }

          &:not(:disabled):hover {
            .mat-button-ripple {
              background: rgba($white, 0.08);
            }
          }
        }
      }

      &.legend-button {
        height: auto;
        padding: 4px 6px;
        min-width: auto;
        border-radius: 4px;
        white-space: normal;
        word-break: break-word;

        &.hidden {
          opacity: 0.4;
          filter: grayscale(1);
        }

        .mat-button-wrapper {
          span {
            &.label-square {
              flex: 0 0 14px;
              width: 14px;
              height: 14px;
              margin-right: 6px;
              border-radius: 2px;
            }

            &.legend-text {
              font-weight: 400;
              font-size: 13px;
              text-align: left;
              line-height: 1.2;
            }
          }
        }
      }

      &.pill-button {
        height: auto;
        font-size: 12px;
        padding: 6px 8px;
        min-width: auto;
        background: rgba($white, 0.08);

        .legend-circle {
          display: inline-block;
          width: 12px;
          height: 12px;
          border: 3px solid;
          border-radius: 50%;
          margin-right: 4px;
        }

        &.hidden {
          opacity: 0.4;
          filter: grayscale(1);
        }
      }

      &.text-button {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.005em;
        color: $lynch;
        height: auto;
        min-width: auto;
        padding: 8px 10px;
      }
    }
  }

  &.mat-icon-button {
    width: 36px;
    height: 36px;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.mat-hint-button {
      .mat-button-focus-overlay {
        opacity: 0.04;
      }

      &:hover {
        .mat-button-focus-overlay {
          opacity: 0.1;
        }
      }
    }

    &.md {
      width: 24px;
      height: 24px;

      mat-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }

  &.action-link-button {
    font-size: 12px;
    color: $yellow-orange;
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    &.text-decoration-underline {
      text-decoration: underline !important;
    }

    mat-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    mat-spinner {
      margin-left: 5px;
    }

    &.disabled {
      color: $lynch;
    }

    &:not(:disabled) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mat-button-focus-overlay {
    background: currentColor;
  }

  &.slide-countdown {
    position: relative;
    overflow: hidden;

    &:disabled {
      opacity: 1 !important;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: rgba($ebony, 0.3);
        animation-name: slide;
        animation-duration: inherit;
        animation-timing-function: ease-in;
      }
    }
  }
}

.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba($white, 0.3);
}

.mat-form-field-prefix {
  .square-icon-button {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
  }
}

@keyframes slide {
  0% {
    width: 100%;
  }

  80% {
    width: 20%;
  }

  100% {
    width: 0;
  }
}
