@import "src/assets/styles/vars/media";
@import "src/assets/styles/vars/colors";
@import "src/assets/styles/mixins/toast-colors";

snack-bar-container {
  &.mat-snack-bar-container {
    min-width: auto;
    min-height: auto;
    padding: 16px;
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid;
    max-width: 100%;
    backdrop-filter: blur(10px);

    @media screen and (min-width: $media-tablet-sm) {
      max-width: 30vw;
    }

    &.error {
      @include toastColors($pomegranate, rgba($pomegranate, 0.3));
    }

    &.success {
      @include toastColors($mountain-meadow, rgba($mountain-meadow, 0.3));
    }

    &.warning {
      @include toastColors($yellow-orange, rgba($yellow-orange, 0.3));
    }

    &.info {
      @include toastColors($azure-radiance, rgba($azure-radiance, 0.3));
    }

    .mat-simple-snack-bar-content {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.005em;
      color: $white;
    }

    .mat-simple-snackbar-action {
      margin: 0 -4px 0 29px;

      button {
        height: 24px !important;
        padding: 0 !important;
        width: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: $white;
      }
    }
  }
}
