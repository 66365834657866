@import "src/assets/styles/vars/colors";
@import "src/assets/styles/mixins/skeleton";

ul {
  &.list-view {
    &.loading {
      li {
        > span {
          &:nth-child(2) {
            @include skeleton($bright-gray);
            display: inline-block;
            height: 12px;
            width: 80px;
          }
        }
      }
    }

    li {
      > i {
        width: 40px;
        height: 40px;
      }

      .small-copy {
        margin-left: 0 !important;
      }

      > span {
        font-weight: 500;
        font-size: 13px;

        &:nth-child(1) {
          color: $manatee;
          margin-right: 5px;
        }

        &.no-value {
          color: $manatee;
        }
      }
    }
  }
}
