@mixin toastColors($border-color, $background-color) {
  border-color: $border-color;
  background: $background-color;

  &.has-icon {
    > mat-icon {
      color: $border-color;
    }
  }
}
