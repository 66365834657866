@import "src/assets/styles/vars/colors";

.status-circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &_clickable {
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &.status-default {
    background: $manatee;
  }

  &.status-pre-warning {
    background-color: $sunglow;
  }

  &.status-warning {
    background-color: $chilean-fire;
  }

  &.status-error {
    background-color: $pomegranate;
  }

  &.status-success {
    background-color: $mountain-meadow;
  }
}
