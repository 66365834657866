@import "src/assets/styles/vars/colors";

@mixin rightTriangle {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid $bright-gray;
  margin: auto 0;
  top: 0;
  bottom: 0;
  left: 100%;
}

@mixin leftTriangle {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid $bright-gray;
  margin: auto 0;
  top: 0;
  bottom: 0;
  right: 100%;
}

@mixin triangleTop {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $bright-gray;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@mixin triangleBottom {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $bright-gray;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.mat-tooltip-panel {
  mat-tooltip-component {
    .mat-tooltip {
      background: $bright-gray;
      color: $white;
      overflow: visible !important;
      position: relative;
      text-align: center;
      max-width: 200px;
      font-weight: 400;
      padding: 6px 12px;
      border-radius: 6px;
      line-height: 1.4;
      font-size: 12px;

      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
      }

      &[style*="transform-origin: center top"] {
        margin-top: 5px;

       /* &:before {
          @include triangleTop;
        }*/
      }

      &[style*="transform-origin: center bottom"] {
        margin-bottom: 5px;

       /* &:before {
          @include triangleBottom;
        }*/
      }

      &[style*="transform-origin: right center"] {
        /*&:before {
          @include rightTriangle;
        }*/
      }

      &[style*="transform-origin: left center"] {
        /*&:before {
          @include leftTriangle;
        }*/
      }
    }
  }
}
