@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";
@import "src/assets/styles/vars/animations";

mat-radio-button {
  &.outlined {
    display: block;
    width: 100%;
    border: 1px solid $mystic;
    border-radius: 6px;
    transition: $base-transition;

    &:hover {
      background: rgba(52, 92, 139, 0.04);
    }

    label {
      padding: 15px 17px;
    }
  }

  .mat-radio-label {
    .mat-radio-container {
      width: 22px;
      height: 22px;

      .mat-radio-outer-circle {
        width: 22px;
        height: 22px;
      }

      .mat-radio-inner-circle {
        width: 22px;
        height: 22px;
      }

      .mat-radio-ripple {
        display: none !important;
      }
    }

    .mat-radio-label-content {
      padding-left: 11px;
    }
  }

  &:not(.mat-radio-disabled) {
    &:hover {
      .mat-radio-label {
        .mat-radio-container {
          .mat-radio-outer-circle {
            border-color: $yellow-orange;
          }
        }
      }
    }

    &.mat-radio-checked {
      &:hover {
        .mat-radio-label {
          .mat-radio-container {
            .mat-radio-inner-circle {
              background-color: $yellow-orange;
            }
          }
        }
      }
    }
  }

  &:not(.mat-radio-checked) {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $mystic;
          border-width: 1px;
        }
      }
    }
  }

  &.mat-radio-disabled {
    &.mat-radio-checked {
      opacity: 0.4;

      .mat-radio-label {
        .mat-radio-container {
          .mat-radio-outer-circle {
            border-color: $yellow-orange;
          }

          .mat-radio-inner-circle {
            background-color: $yellow-orange;
          }
        }
      }
    }

    &:not(.mat-radio-checked) {
      .mat-radio-label {
        .mat-radio-container {
          .mat-radio-outer-circle {
            background-color: rgba($mystic, 0.3);
          }
        }
      }
    }
  }
}

mat-radio-group {
  &.column-type {
    display: flex;
    flex-direction: column;

    mat-radio-button {
      & + mat-radio-button {
        margin-top: 24px;
      }
    }
  }

  &.reverse-horizontal {
    mat-radio-button {

      & + mat-radio-button {
        margin-top: 32px;
      }

      .mat-radio-label {
        flex-direction: row-reverse;

        .mat-radio-label-content {
          padding: 0;
          flex: 1;
        }
      }
    }
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $yellow-orange;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $yellow-orange;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: inherit;
}

mat-radio-button.mat-radio-disabled {
  opacity: 0.4;
}
