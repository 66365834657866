@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";
@import "src/assets/styles/vars/animations";
@import "src/assets/styles/mixins/no-data";

@include noDataOverlay;

.mat-table-wrapper {
  position: relative;
  overflow-x: auto;
  width: 100%;

  & + mat-paginator {
    margin-top: 8px;
  }

  mat-table {
    min-height: 526px;
    min-width: 100%;
    background: none;
    width: fit-content;

    & + mat-paginator {
      margin-top: 6px;
    }

    mat-header-row {
      min-height: 40px;
      background-color: $tuna;
      border-radius: 6px;
      margin-bottom: 6px;

      mat-header-cell {
        font-size: 12px;
        color: rgba($white, 0.8);
        white-space: nowrap;
      }
    }

    .mat-cell, .mat-footer-cell {
      color: $white;
      font-size: 14px;
    }

    mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
      padding-left: 12px;
      padding-right: 12px;
    }

    mat-cell.mat-column-actions {
      margin-left: auto;
    }

    mat-cell:not(:first-of-type), mat-header-cell:not(:first-of-type), mat-footer-cell:not(:first-of-type) {
      padding-right: 12px;
    }

    mat-row, mat-header-row, mat-footer-row {
      border-bottom: 0;
    }

    a {
      &.row-link {
        color: inherit !important;
        text-decoration: none !important;
        cursor: default;
      }
    }

    mat-row {
      border-radius: 6px;

      &.has-single-view {
        cursor: pointer;

        &:not(.selected):hover {
          background-color: rgba($lynch, 0.2);
        }

        &.selected {
          background-color: rgba($yellow-orange, 0.1);
        }
      }

      &.soft-transition {
        transition: $long-transition;
      }

      &.visited {
        background-color: rgba($fantasy, 0.1);
      }
    }
  }
}

mat-paginator {
  &.mat-paginator {
    background-color: $tuna;
    color: $white;
    border-radius: 6px;

    .mat-paginator-container {
      min-height: 46px;
      padding: 6px 12px;

      .mat-paginator-page-size {
        align-items: center;
        margin-right: 0;

        .mat-paginator-page-size-select {
          margin-top: 0;
          margin-bottom: 0;

          .mat-form-field-wrapper {
            padding: 0;

            .mat-select-value {
              color: $white;
            }

            .mat-select-arrow {
              color: $white;
            }

            .mat-form-field-underline {
              display: none !important;
            }

            .mat-form-field-infix {
              background: rgba($white, 0.1);
              border-radius: 6px;
              border: none;
              padding: 6px 8px;
            }
          }
        }
      }

      .mat-paginator-range-label {
        margin: 0 16px;
        font-weight: 500;
      }

      button.mat-icon-button, a.mat-icon-button {
        width: 32px;
        height: 32px;

        .mat-paginator-icon {
          width: 24px;
          fill: currentColor;
        }
      }
    }
  }
}

.mat-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.mat-sort-header-arrow {
  color: $yellow-orange !important;
}
