@import "src/assets/styles/vars/colors";

.pulse {
  position: relative;

  &:before{
    content: '';
    display: block;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    position: absolute;
    animation: infinite animation-pulse 2s ease-out;
    opacity: 0;
    border-width: 3px;
    border-style: solid;
    border-color: $whisper;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      top: 2px;
      left: 2px;
    }
  }
}

@keyframes animation-pulse {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
