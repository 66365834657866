@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

.floating-form {
  background: rgba($martinique, 0.85);
  border-radius: 20px;
  padding: 60px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $media-mobile-lg - 1px) {
    padding: 24px;
  }

  &_heading {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 24px;
    text-align: center;
    display: block;

    @media screen and (max-width: $media-mobile-lg - 1px) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
    }
  }

  .form-group {
    padding-bottom: 0 !important;
  }
}

.create-entity-form {
  padding: 8px 0;
  margin: 24px 0 12px;

  @media screen and (max-width: $media-mobile-lg - 1px) {
    margin: 12px 0 0;
    padding: 0;
  }

  h2 {
    &.create-entity-form_title {
      font-weight: 600;
      margin-bottom: 24px;
    }
  }

  h3 {
    &.create-entity-form_subtitle{
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 1.4;
      font-weight: 600;
    }
  }

  .row {
    margin-left: -12px;
    margin-right: -12px;

    > .col {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;

  &:not(.no-gutters) {
    padding-bottom: 16px;
  }
}

[class^="form-section"] {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  &.form-section-md {
    max-width: 650px;
  }

  &.form-section-sm {
    max-width: 450px;
  }

  &.form-section-xs {
    max-width: 350px;
  }

  & + [class^="form-section"] {
    margin-top: 16px;
  }

  &:not(:first-child):last-child {
    margin-top: 36px;

    @media screen and (max-width: $media-mobile-lg - 1px) {
      margin-top: 16px;
    }
  }
}

textarea {
  &.json-area {
    min-height: 125px !important;
  }
}

.toggle-password-visibility {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  bottom: 0;
  padding: 0;
  border: 0;
  line-height: 1;
  display: inline-flex;
  background-color: transparent;
  width: 24px;
  height: 24px;
}

.has-toggle-password-btn {
  input {
    max-width: calc(100% - 24px);
  }
}
