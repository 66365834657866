@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/animations";
@import "src/assets/styles/vars/media";

mat-tab-group {
  mat-tab-header {
    .mat-mdc-tab-labels {
      .mat-mdc-tab {
        &.mdc-tab {
          flex: 0 0 auto !important;
          background-color: $mirage;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          font-weight: 500;
          color: rgba($white, 0.7);

          &:hover {
            color: $white;
          }

          &.mdc-tab-indicator--active {
            background-color: $steel-gray;
            color: $white;
          }
        }
      }
    }
  }

  mat-tab-body {
    background-color: $steel-gray;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    padding: 24px;
  }
}
