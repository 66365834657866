@mixin scrollbar($trackbar-color, $thumb-color){
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $trackbar-color;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 3px;
  }
}
