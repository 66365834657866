@import "src/assets/styles/vars/colors";

@mixin skeleton($color) {
  position: relative;
  overflow: hidden;
  background: $color;
  text-indent: -999px;
  width: 100px;
  border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    animation: skeletonLoading 2s infinite;
    z-index: 1;
  }
}

@keyframes skeletonLoading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}
