@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/media";

.mat-modal-stepper {
  &.mat-stepper-horizontal, &.mat-stepper-vertical {
    background: transparent;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  &.mat-modal-stepper--hide-header {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  .mat-step-header {
    padding: 0;
    height: auto;
    pointer-events: none !important;
    touch-action: none !important;

    .mat-step-icon {
      color: $white;
      background-color: rgba($white, 0.1);
    }

    .mat-step-icon-selected {
      background: $primary-gradient;
      color: $white;
    }
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-color: rgba($white, 0.1);
  }

  .mat-step-label:empty {
    display: none !important;
  }

  .mat-horizontal-stepper-header .mat-step-icon {
    margin-right: 0;
  }

  .mat-stepper-horizontal-line {
    margin: 0 12px;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 16px;
  }

  @media screen and (max-width: $media-mobile-lg - 1px) {
    .mat-horizontal-stepper-wrapper {
      height: 100%;

      .mat-horizontal-content-container {
        flex: 1;

        .mat-horizontal-stepper-content {
          &:not(.mat-horizontal-stepper-content-inactive) {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

}


