@import "src/assets/styles/vars/colors";
@import "src/assets/styles/vars/animations";
@import "src/assets/styles/vars/media";

.cdk-drop-list {
  &.dnd-list {
    background: #353442;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    display: block;
    min-height: 282px;
    height: calc(100vh - 0.5rem - 368px);
    transform: $base-transition;

    @media screen and (max-width: $media-tablet-sm - 1px) {
      min-height: 100px;
      height: calc(100% - 24px - 0.5rem);
    }

    &.cdk-drop-list-dragging {
      background: rgba($white, 0.5);
    }

    .dnd-list-item {
      &.cdk-drag-placeholder {
        background: $primary-gradient;
      }

      &:not(.dnd-list-item_disabled){
        &:hover {
          background: #66656F;
        }
      }
    }
  }
}

.dnd-list-item {
  background: #43414E;
  margin: 6px;
  border-radius: 4px;
  padding: 6px 12px;
  min-height: 40px;
  font-weight: 500;
  user-select: none;
  display: flex;
  align-items: center;

  &:not(.dnd-list-item_disabled){
    cursor: pointer;
  }

  &.dnd-list-item_disabled {
    opacity: 0.6;
  }
}

.cdk-drag {
  &.dnd-list-item {
    &.cdk-drag-preview {
      cursor: move;
      background: $secondary-gradient;
      color: $ebony;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }
}
