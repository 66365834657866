@import "../../vars/colors";

.mat-mdc-slider {
  &.primary-theme {
    .mdc-slider__track--active_fill {
      border-color: $yellow-orange !important;
    }

    .mdc-slider__thumb-knob {
      border-color: $yellow-orange !important;
      background: $primary-gradient !important;
    }

    .mdc-slider__value-indicator {
      background: $secondary-gradient !important;
      color: $ebony;

      &:before {
        border-top-color: $white !important;
      }
    }
  }

  .mdc-slider__value-indicator-text {
    white-space: nowrap;
  }

  .mdc-slider__value-indicator {
    transform: scale(1) !important;
  }

  .mdc-slider__track--inactive {
    background-color: rgba($white, 0.5) !important;
  }

  .mdc-slider__track--active_fill {
    border-color: $mountain-meadow !important;
  }

  .mdc-slider__thumb-knob {
    border-color: $mountain-meadow !important;
    background-color: $mountain-meadow !important;
  }

  .mdc-slider__value-indicator {
    background-color: $lynch !important;

    &:before {
      border-top-color: $lynch !important;
    }
  }
}
