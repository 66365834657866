@import "src/assets/styles/vars/colors";

@mixin tagStates {
  &-unset {
    background: $manatee;
  }

  &-default {
    color: rgba($white, 0.8);
    background-color: rgba($white, 0.1);
  }

  &-info {
    color: $sail;
    background-color: rgba($sail, 0.1);
  }

  &-pre-warning {
    color: $white;
    background-color: $sunglow;
  }

  &-warning {
    color: $white;
    background-color: $chilean-fire;
  }

  &-success {
    color: $white;
    background-color: $mountain-meadow;
  }

  &-danger {
    color: $white;
    background-color: $primary-red;
  }

  &-primary {
    color: $white;
    background: $primary-gradient;
  }

  &-warning-t {
    color: $chilean-fire;
    background-color: rgba($chilean-fire, 0.1);
  }

  &-info-t {
    color: $azure-radiance;
    background-color: rgba($azure-radiance, 0.1);
  }

  &-success-t {
    color: $mountain-meadow;
    background-color: rgba($mountain-meadow, 0.1);
  }

  &-danger-t {
    color: $pomegranate;
    background-color: rgba($pomegranate, 0.1);
  }
}

.tag {
  font-weight: 500;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 15px;
  display: inline-block;
  vertical-align: middle;
  max-width: 250px;
  color: $white;
  background-color: rgba($white, 0.1);

  &-small {
    padding: 2px 4px !important;
    font-size: 12px !important;
    border-radius: 2px !important;
    line-height: 1 !important;
  }

  &-x-small {
    padding: 2px 4px !important;
    font-size: 11px !important;
    border-radius: 2px !important;
  }

  @include tagStates;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;

  @include tagStates;
}

.label-tag {
  display: inline-block;
  margin-left: auto;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  width: 20px;
  flex: 0 0 20px;
  max-width: 20px;
  text-align: center;
  background: $secondary-gradient;
  color: $ebony;
  height: 15px;
  line-height: 15px;
  border-radius: 1.5px;
}

.code-tag {
  margin-left: auto;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  background: $secondary-gradient;
  color: $ebony;
  height: 15px;
  padding: 2px 5px;
  border-radius: 1.5px;
}
