$white: #ffffff;
$white-lilac: #EEF0F8;
$alabaster: #FCFCFC;
$catskill-white: #F5F8FA;
$gallery: #ECECEC;
$whisper: #EAECF4;
$athens-gray: #F3F3F4;
$mystic: #E6E8F1;
$link-water: #EAF0F8;
$givry: #F8D1C9;
$ghost: #CED4DA;
$iron: #D0D0D3;
$manatee: #9599A9;
$waterloo: #797F98;
$lynch: #5F7390;
$mine-shaft: #212121;
$bright-gray: #3F4254;
$mirage: #181726;
$ebony: #100E1F;
$black: #000000;
$tuna: #3a374a;
$martinique: #2C2945;
$cornflower-blue: #6666FF;
$blue-ribbon: #1144EE;
$governor-bay: #3535D0;
$chambray: #345c8b;
$primary-gradient: linear-gradient(92.69deg, #FEA04C 0%, #E5508A 100%);
$secondary-gradient: linear-gradient(90deg, #D3E6F5 0%, #F0F7FC 100%);
$fantasy: #FCF5F4;
$pomegranate: #F33434;
$primary-red: #e94f4f;
$chilean-fire: #F57808;
$sunglow: #FFD12D;
$linen: #FCF9F0;
$mountain-meadow: #14AF56;
$harp: #F3F9F6;
$de-york: #6fcf97;
$azure-radiance: #009EF7;
$sail: #B2D6F7;
$yellow-orange: #FEA04C;
$steel-gray: #252434;
$cranberry: #E5508A;

